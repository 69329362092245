
import { mapState, mapMutations } from "vuex";

export default {
  scrollToTop: true,
  layout: "default",
  transition: "fade",
  head() {
    return this.head;
  },
  components: {},
  data() {
    return {
      head: {},
      page: {},
    };
  },
  async fetch() {
    let self = this;

    const path = "home";

    let page = this.$store.state.directory[path];

    console.log("directory from state", this.$store.state.directory.length);

    if (process.server || page != null) {
      if (page != null) {
        this.$store.commit("page/setPageContent", page);
        this.head = page.head;
        this.page = page;
      } else {
        console.log("server page is null", page == null);

        //this.$nuxt.error({ statusCode: 404, message: 'Page not found' });
      }
    } else if (page == null) {
      console.log("Using client api");

      await self.$axios
        .$post(`/api/content`, {
          query: "page('" + path + "')",
          select: {
            head: "page.title.toSeoHelper",
            breadcrumb: "page.title.toCrumbs",
            blocks: "page.blocks.toBlocksHelper",
          },
        })
        .then((resp) => {
          console.log("client response", resp.result);
          page = resp.result;

          if (page != null) {
            this.$store.commit("page/setPageContent", page);
            this.head = page.head;
            this.blocks = page.blocks;
            this.page = page;

            console.log("client page found", this.page);
          } else {
            console.log("page is null", page == null);
            this.$nuxt.error({ statusCode: 404, message: "Page not found" });
          }
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }

          if (path != "search") {
            return self.$nuxt.error({
              statusCode: 404,
              message: error.message,
            });
          }
        });
    }
  },
};
